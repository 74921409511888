<script>
/**
 * AttendanceLog component
 */
export default {
  props: {
    log: {
      default: [],
    },
    title: {
      default: "",
    },
    subTitle: {
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<template>
  <b-card :title="title" :sub-title="subTitle">
    <b-table striped hover :items="log">
      <template #cell()="row">
        {{ row.value || "---" }}
      </template>
    </b-table>
  </b-card>
</template>