<script>
/**
 * Requests component
 */
export default {
  props: {
    requests: {
      default: [],
    },
    showPagination: {
      default: true,
      type: Boolean,
    },
    showFilter: {
      default: true,
      type: Boolean,
    },
    perPage: {
      default: 10,
      type: Number,
    },
  },
  page: {},
  components: {},
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "start_date",
      sortDesc: true,
      fields: [
        { key: "id", sortable: true },
        { key: "employee", sortable: true },
        { key: "request_type", label: "Request Type", sortable: true },
        { key: "duration", sortable: true },
        { key: "status", sortable: true },
        { key: "action" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.requests.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.requests.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(row) {
      console.log(row);
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div v-if="showPagination" class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div v-if="showFilter" class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <div class="table-responsive">
      <b-table
        hover
        striped
        :fields="fields"
        :items="requests"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(employee)="row">
          <a class="text-nowrap" :href="`/profile/${row.item.id}`">
            {{ row.value }}
          </a>
        </template>
        <template #cell(duration)="row">
          <div>
            <div>{{ row.value }}</div>
            <small>
              {{ row.item.start_date | moment("DD/MM/YYYY") }}
            </small>
            -
            <small>
              {{ row.item.end_date | moment("DD/MM/YYYY") }}
            </small>
          </div>
        </template>
        <template #cell(status)="row">
          <b-badge :variant="row.value" class="text-capitalize">
            {{ row.item.status_text || "---" }}
          </b-badge>
        </template>
        <template #cell(action)="row">
          <b-btn-group
            v-if="row.item.status_text === 'pending'"
            :user_id="row.item.id"
          >
            <b-button variant="link">
              <i class="fa fa-check text-success"></i>
            </b-button>
            <b-button variant="link">
              <i class="fa fa-times text-danger"></i>
            </b-button>
          </b-btn-group>
        </template>
        <template #cell()="row">
          <div class="text-nowrap text-capitalize">
            {{ row.value || "---" }}
          </div>
        </template>
      </b-table>
    </div>
    <b-row>
      <b-col>
        <div
          v-if="showPagination"
          class="dataTables_paginate paging_simple_numbers float-right"
        >
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>