<script>
/**
 * Earning Reports component
 */
export default {
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <b-card>
      <p class="h4">Your last payment was submitted on August 31.</p>
      <b-button block v-b-modal.payslip-modal variant="link" class="float-right"
        >See your last slip</b-button
      >
    </b-card>
    <b-modal
      id="payslip-modal"
      size="lg"
      centered
      title="Payslip for the month of feb 2022"
      title-class="text-uppercase"
      hide-footer
    >
      <table class="table table-borderless table-condensed">
        <thead class="bg-light">
          <tr>
            <th colspan="2">Employee Pay Summary</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Employee Name</td>
            <td>John Doe</td>
          </tr>
          <tr>
            <td>Employee ID</td>
            <td>104</td>
          </tr>
          <tr>
            <td>Designation</td>
            <td>Software Engineer</td>
          </tr>
          <tr>
            <td>Pay Period</td>
            <td>Feb 1, 2022 - Feb 28, 2022</td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered table-hover table-condensed mt-3">
        <thead class="bg-light">
          <tr>
            <th colspan="2">Salary Terms</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Insurance base</td>
            <td>7,787.55 EGP</td>
          </tr>
          <tr>
            <td>Employee share</td>
            <td>856.63 EGP (11%)</td>
          </tr>
          <tr>
            <td>Company share</td>
            <td>1,460.17 EGP (18.75%)</td>
          </tr>
          <tr>
            <td>Income Tax</td>
            <td>995.79 EGP</td>
          </tr>
        </tbody>
      </table>
      <b-row>
        <b-col md="6">
          <table class="table table-bordered table-hover table-condensed mb-0">
            <thead class="bg-light">
              <tr>
                <th colspan="2">Earnings</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Overtime</td>
                <td>0 EGP</td>
              </tr>
              <tr>
                <td>Commission</td>
                <td>0 EGP</td>
              </tr>
              <tr>
                <td>Expense Claims</td>
                <td>0 EGP</td>
              </tr>
              <tr>
                <td>Other Allowances</td>
                <td>0 EGP</td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col md="6">
          <table class="table table-bordered table-hover table-condensed mb-0">
            <thead class="bg-light">
              <tr>
                <th colspan="2">Deductions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Medical Insurance</td>
                <td>220 EGP</td>
              </tr>
              <tr>
                <td>Other Deductions</td>
                <td>0 EGP</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <table class="table bg-light">
            <thead>
              <tr>
                <th class="ml-1">Total</th>
                <th class="text-right">0 EGP</th>
              </tr>
            </thead>
          </table>
        </b-col>
        <b-col md="6">
          <table class="table bg-light">
            <thead>
              <tr>
                <th class="ml-1">Total</th>
                <th class="text-right">220 EGP</th>
              </tr>
            </thead>
          </table>
        </b-col>
      </b-row>
      <p class="h5 bg-dark text-white p-3">
        <small>Gross Salary</small><span class="mx-3">10,123.82 L.E</span>
      </p>
      <p class="h5 bg-dark text-white p-3">
        <small>Total Net Pay</small><span class="mx-3">8,000.00 L.E</span>
      </p>
      <b-row align-h="center" align-v="center">
        <b-col md="auto">
          <b-button variant="outline-success">
            <i class="fa fa-file-pdf"></i> PDF
          </b-button>
        </b-col>
        <b-col md="auto">
          <b-button variant="outline-success">
            <i class="fa fa-print"></i> Print
          </b-button>
        </b-col>
        <b-col md="auto">
          <b-button variant="outline-success">
            <i class="fa fa-file-excel"></i> Excel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>