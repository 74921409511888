const requests = [
  {
    id: 1,
    employee: 'Jesse Black',
    request_type: 'vacation',
    duration: 4,
    start_date: '2022-02-20',
    end_date: '2022-02-21',
    status: 'success',
    status_text: 'approved',
  },
  {
    id: 2,
    employee: 'Henrietta Carsberg',
    request_type: 'attendance',
    duration: 5,
    start_date: '2022-03-29',
    end_date: '2022-03-30',
    status: 'danger',
    status_text: 'rejected',
  },
  {
    id: 3,
    employee: 'Phylys David',
    request_type: 'vacation',
    duration: 0.5,
    start_date: '2022-01-09',
    end_date: '2022-01-10',
    status: 'success',
    status_text: 'approved',
  },
  {
    id: 4,
    employee: 'Brena Shivell',
    request_type: 'vacation',
    duration: 2,
    start_date: '2022-02-14',
    end_date: '2022-02-15',
    status: 'warning',
    status_text: 'pending',
  },
  {
    id: 5,
    employee: 'Carmon Tuiller',
    request_type: 'vacation',
    duration: 4,
    start_date: '2022-12-08',
    end_date: '2022-12-09',
    status: 'warning',
    status_text: 'pending',
  },
]

const types = {
  attendance: [
    {
      id: 1,
      name: 'Work From Home',
      status: 'success',
      status_text: 'active',
    },
  ],
  vacation: [
    {
      id: 1,
      name: 'Annual Leave',
      status: 'success',
      status_text: 'active',
    },
  ],
}

export { requests, types }
