<script>
/**
 * Recent requests component
 */

const VACATIONS = {
  annual: {
    balance: 30,
    limit: 30,
  },
  compassionate: {
    balance: 5,
    limit: 5,
  },
  emergency: {
    balance: 2,
    limit: 6,
  },
  unpaid: {
    balance: 0,
    limit: 0,
  },
  sick: {
    balance: 30,
    limit: 30,
  },
};

export default {
  props: ["title", "allowAdd"],
  data() {
    return {
      VACATIONS,
      request_type: "",
      attendance_type: "",
      permission_type: "",
      vacation_type: "",
      dummyData: {
        requests: [
          {
            icon: "ri-calendar-2-fill",
            date: "28 Apr, 2020 ",
            title: "Work from home",
            status: "success",
            statusText: "approved",
          },
          {
            icon: "ri-calendar-2-fill",
            date: "21 Apr, 2020 ",
            title: "Annual Leave",
            status: "danger",
            statusText: "rejected",
          },
          {
            icon: "ri-calendar-2-fill",
            date: "17 Apr, 2020 ",
            title: "Leave",
            status: "success",
            statusText: "approved",
          },
          {
            icon: "ri-calendar-2-fill",
            date: "07 Apr, 2020",
            title: "Attendance",
            status: "warning",
            statusText: "pending",
          },
          {
            icon: "ri-calendar-2-fill",
            date: "05 Apr, 2020 ",
            title: "Extra time",
            status: "warning",
            statusText: "pending",
          },
          {
            icon: "ri-calendar-2-fill",
            date: "02 Apr, 2020 ",
            title: "Leave",
            status: "success",
            statusText: "approved",
          },
        ],
        reminders: [
          {
            icon: "ri-calendar-2-fill",
            date: "6 Oct, 2020 ",
            title: "Armed Forces Day",
            status: "success",
            statusText: "holiday",
          },
          {
            icon: "ri-calendar-2-fill",
            date: "31 Dec, 2020 ",
            title: "Your Annual Vacation",
            status: "info",
            statusText: "leave",
          },
        ],
      },
    };
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <b-button
        v-if="allowAdd"
        v-b-modal="`modal-center-${title}`"
        variant="primary"
        class="float-right"
      >
        <i class="mdi mdi-plus"></i>
      </b-button>
      <b-modal
        :id="`modal-center-${title}`"
        centered
        :title="`new ${title}`"
        title-class="font-18 text-capitalize"
        hide-footer
      >
        <form class="form-horizontal" role="form">
          <b-form-group
            label-cols-lg="4"
            label="Request Type"
            label-for="request_type"
          >
            <select class="form-control" v-model="request_type">
              <option value="">-- Select a type --</option>
              <option value="attendance">Attendance</option>
              <option value="vacation">Vacation</option>
            </select>
          </b-form-group>
          <div v-if="request_type === 'attendance'">
            <b-form-group
              label-cols-lg="4"
              label="Attendance Type"
              label-for="attendance_type"
            >
              <select class="form-control" v-model="attendance_type">
                <option value="">-- Select a type --</option>
                <option value="wfh">Work from home</option>
                <option value="permission">Permission</option>
                <!-- <option value="meeting">Outdoor Meeting</option> -->
              </select>
            </b-form-group>
            <b-form-group
              label-cols-lg="4"
              :label="`${attendance_type === 'wfh' ? 'Start ' : ''}Date`"
              label-for="start_date"
            >
              <b-form-input type="date"></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="attendance_type === 'wfh'"
              label-cols-lg="4"
              label="End Date"
              label-for="end_date"
            >
              <b-form-input type="date"></b-form-input>
            </b-form-group>
            <div v-if="attendance_type === 'meeting'">
              <b-form-group
                label-cols-lg="4"
                label="From"
                label-for="from_time"
              >
                <b-form-input type="time"></b-form-input>
              </b-form-group>
              <b-form-group label-cols-lg="4" label="To" label-for="to_time">
                <b-form-input type="time"></b-form-input>
                <p class="text-muted mt-2">
                  Please consider transportation time while selecting meeting
                  start and end time.
                </p>
              </b-form-group>
              <b-form-group
                label-cols-lg="4"
                label="Place of meeting"
                label-for="meeting_location"
              >
                <b-form-input type="text"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols-lg="4"
                label="Purpose of meeting"
                label-for="meeting_purpose"
              >
                <b-form-input type="text"></b-form-input>
              </b-form-group>
            </div>
            <div v-if="attendance_type === 'permission'">
              <b-form-group
                label-cols-lg="4"
                label="Permission Type"
                label-for="permission_type"
              >
                <select class="form-control" v-model="permission_type">
                  <option value="">-- Select a type --</option>
                  <option value="late_attendance">Late Attendance</option>
                  <option value="early_leave">Early Leave</option>
                </select>
              </b-form-group>
              <b-form-group
                label-cols-lg="4"
                label="Number of hours"
                label-for="hours"
              >
                <b-form-input type="number" />
              </b-form-group>
            </div>
          </div>
          <div v-if="request_type === 'vacation'">
            <b-form-group
              label-cols-lg="4"
              label="Vacation Type"
              label-for="vacation_type"
            >
              <select class="form-control" v-model="vacation_type">
                <option value="">-- Select a type --</option>
                <option value="annual">Annual</option>
                <option value="compassionate">Compassionate</option>
                <option value="emergency">Emergency</option>
                <option value="unpaid">Unpaid</option>
                <option value="sick">Sick</option>
              </select>
            </b-form-group>
            <b-form-group
              label-cols-lg="4"
              label="Start Date"
              label-for="start_date"
            >
              <b-form-input type="date"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols-lg="4"
              label="End Date"
              label-for="end_date"
            >
              <b-form-input type="date"></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="
                VACATIONS[vacation_type] && VACATIONS[vacation_type].limit > 0
              "
              label-cols-lg="4"
              label="Remaining balance"
              label-for="remaining_balance"
            >
              <b-input-group append="Day(s)">
                <b-form-input
                  type="text"
                  disabled
                  :value="
                    VACATIONS[vacation_type].balance +
                    ' / ' +
                    VACATIONS[vacation_type].limit
                  "
                />
              </b-input-group>
            </b-form-group>
          </div>
          <b-form-group label-cols-lg="4" label="Note" label-for="note">
            <b-form-textarea name="note" />
          </b-form-group>
          <div class="text-right">
            <b-button variant="primary">Submit</b-button>
          </div>
        </form>
      </b-modal>
      <h4 class="card-title text-capitalize mb-4">{{ title }}</h4>

      <div data-simplebar style="max-height: 330px">
        <ul class="list-unstyled activity-wid">
          <li
            class="activity-list"
            v-for="(data, index) in dummyData[title]"
            :key="index"
          >
            <div class="activity-icon avatar-xs">
              <span
                :class="`avatar-title bg-soft-${data.status} text-${data.status} rounded-circle`"
              >
                <i :class="`${data.icon}`"></i>
              </span>
            </div>
            <div>
              <div>
                <h5 class="font-size-13">
                  {{ data.date }}
                  <small :class="`text-${data.status} text-uppercase`">{{
                    data.statusText
                  }}</small>
                </h5>
              </div>

              <div>
                <p class="text-muted mb-0">{{ data.title }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>