<script>
import AttendanceLog from "./attendance-log";
import RecentRequests from "./recent-requests";
import { useStopwatch } from "vue-timer-hook";
import LatestPayroll from "./latest-payroll.vue";

const startTime = new Date();
startTime.setHours(9);
startTime.setMinutes(30);
startTime.setSeconds(0);
const stopwatchOffset = new Date();
const autoStart = true;
const stopwatch = useStopwatch(
  (stopwatchOffset.getTime() - startTime.getTime()) / 1000,
  autoStart
);

/**
 * Dashboard component
 */
export default {
  components: {
    RecentRequests,
    AttendanceLog,
    LatestPayroll,
  },
  data() {
    return {
      startTime,
      stopwatch,
      attendance: autoStart,
      title: `${this.$store.state.auth?.currentUser?.user?.name} Dashboard`,
      items: [
        {
          text: "HR",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      attendanceLog: [
        {
          Date: "05-10-2022",
          first_in: "09:05",
          last_out: "17:00",
          hours: "07:55",
        },
        {
          Date: "04-10-2022",
          first_in: "10:00",
          last_out: "18:00",
          hours: "08:00",
          _rowVariant: "danger",
        },
        {
          Date: "03-10-2022",
          first_in: "09:00",
          last_out: "17:00",
          hours: "08:00",
        },
        {
          Date: "02-10-2022",
          first_in: "09:00",
          last_out: "16:00",
          hours: "07:00",
          _rowVariant: "warning",
        },
        {
          Date: "01-10-2022",
          first_in: "09:00",
          last_out: "17:00",
          hours: "08:00",
        },
      ],
    };
  },
  methods: {
    toggleAttendance() {
      this.attendance = !this.attendance;
      if (this.stopwatch.isRunning) {
        this.stopwatch.pause();
      } else {
        this.stopwatch.start();
      }
    },
    putLeadingZero(n) {
      if (n < 10) {
        return `0${n}`;
      }
      return n;
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        <b-card class="text-center">
          <img
            class="rounded-circle avatar-xl"
            alt="200x200"
            src="@/assets/images/users/avatar-3.jpg"
            data-holder-rendered="true"
          />
          <b-card-title>
            <h5 class="card-title mt-2">Welcome Mohamed</h5>
          </b-card-title>
          <b-card-text v-if="!attendance">Let's get to work!</b-card-text>
          <b-card-text v-if="attendance">You're clocked in!</b-card-text>
          <b-card-text>
            <b-row>
              <b-col>
                <p>First In <br />{{ startTime | moment("hh:mm A") }}</p>
              </b-col>
              <b-col v-if="stopwatch.isRunning">
                Hours<br />
                <span>{{ putLeadingZero(stopwatch.hours) }}</span
                >:<span>{{ putLeadingZero(stopwatch.minutes) }}</span
                >:<span>{{ putLeadingZero(stopwatch.seconds) }}</span>
              </b-col>
              <b-col v-if="!stopwatch.isRunning">
                Last Out<br />{{ new Date() | moment("hh:mm A") }}
              </b-col>
            </b-row>
          </b-card-text>
          <b-button
            :variant="attendance ? 'outline-danger' : 'success'"
            @click="toggleAttendance"
            class="btn-circle btn-checkin"
          >
            <i v-if="!attendance" class="mdi mdi-play mdi-48px"></i>
            <i v-if="attendance" class="mdi mdi-stop mdi-48px"></i>
          </b-button>
        </b-card>
        <RecentRequests title="reminders" />
        <LatestPayroll />
      </div>
      <div class="col-lg-8">
        <RecentRequests title="requests" :allowAdd="true" />
        <AttendanceLog
          title="Attendance Log"
          sub-title="Here are your check-in and check-out records for the past period."
          :log="attendanceLog"
        />
      </div>
    </div>
  </div>
</template>