<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import AdminDashboard from "./admin-dashboard";
import EmployeeDashboard from "./employee-dashboard";
import CompanyDashboard from "./company-dashboard";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    AdminDashboard,
    EmployeeDashboard,
    CompanyDashboard,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth?.currentUser;
    },
  },
  data() {
    return {
      title: `${this.$store.state.auth?.currentUser?.user?.name} Dashboard`,
      items: [
        {
          text: "HR",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="currentUser?.user.name?.toLowerCase() === 'admin'">
      <AdminDashboard />
    </div>
    <div v-else-if="currentUser?.user.name?.toLowerCase() === 'company'">
      <CompanyDashboard />
    </div>
    <div v-else-if="currentUser?.user.name?.toLowerCase() === 'employee'">
      <EmployeeDashboard />
    </div>
  </Layout>
</template>