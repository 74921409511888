<script>
import RecentActivity from "./recent-activity";
import Requests from "../requests/requests";
import { requests } from "../requests/entries";
import AttendanceLog from "./attendance-log.vue";
import RecentRequests from "./recent-requests.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    RecentActivity,
    Requests,
    AttendanceLog,
    RecentRequests,
  },
  computed: {},
  data() {
    return {
      requests,
      title: `${this.$store.state.auth?.currentUser?.user?.name} Dashboard`,
      items: [
        {
          text: "HR",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      attendanceLog: [
        {
          Name: "Russell Lloyd",
          first_in: "09:05",
          last_out: "",
          hours: "",
          _rowVariant: "success",
        },
        {
          Name: "Albert Carpenter",
          first_in: "10:00",
          last_out: "",
          hours: "",
          _rowVariant: "danger",
        },
        {
          Name: "Chad Medina",
          first_in: "09:00",
          last_out: "17:00",
          hours: "08:00",
        },
        {
          Name: "Lulu Luna",
          first_in: "09:00",
          last_out: "16:00",
          hours: "07:00",
          _rowVariant: "warning",
        },
        {
          Name: "Bernice Pearson",
          first_in: "09:00",
          last_out: "17:00",
          hours: "08:00",
        },
      ],
    };
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-8">
        <b-card title="Recent Requests">
          <Requests
            :requests="requests"
            :showFilter="false"
            :showPagination="false"
            :perPage="4"
          />
        </b-card>
      </div>
      <div class="col-lg-4">
        <RecentActivity />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <RecentRequests title="reminders" />
      </div>
      <div class="col-lg-8">
        <AttendanceLog
          :log="attendanceLog"
          title="Attendance Log"
          subTitle="Track your employees"
        />
      </div>
    </div>
  </div>
</template>